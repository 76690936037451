import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "../store/app/user/index";

function guard(to, from, next) {
	useUserStore().token ? next() : next("/login");
}

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/flights/Search.vue"),
	},
	{
		path: "/flights",
		name: "Flights",
		component: () => import("@/views/flights/Search.vue"),
	},
	{
		path: "/flights/result/:id",
		name: "Flight Result",
		component: () => import("@/views/flights/Result.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/flights/results/:id",
		name: "Flight Multi",
		component: () => import("@/views/flights/ResultMultiCities.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/flights/reservation/:id",
		name: "Flight Reservation",
		component: () => import("@/views/flights/Reservation.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/flights/confirmation/:id",
		name: "Flight Confirmation",
		component: () => import("@/views/flights/Confirmation.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/hotels",
		name: "Hotels",
		component: () => import("@/views/hotels/Search.vue"),
	},
	{
		path: "/hotels/result/:id",
		name: "Hotels Result",
		component: () => import("@/views/hotels/Result.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/hotels/details/:id",
		name: "Hotel Details",
		component: () => import("@/views/hotels/Details.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/hotels/book/:id",
		name: "Hotel Book",
		component: () => import("@/views/hotels/Book.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/hotels/confirmation/:id",
		name: "Hotel Confirmation",
		component: () => import("@/views/hotels/Confirmation.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/activities",
		name: "Activities",
		component: () => import("@/views/activities/Search.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/activities/result/:id",
		name: "Activities Result",
		component: () => import("@/views/activities/Result.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/activities/book/:id",
		name: "Activities Booking",
		component: () => import("@/views/activities/Book.vue"),
		meta: {
			hide: true,
		},
	},
	{
		path: "/activities/confirmation/:id",
		name: "Activities Confirmation",
		component: () => import("@/views/activities/Confirmation.vue"),
		meta: {
			hide: true,
		},
	},

	// {
	// 	path: "/",
	// 	name: "Vacation Packages",
	// 	component: () => import("@/views/packages/Search.vue"),
	// },
	{
		path: "/",
		name: "Insurance",
		component: () => import("@/views/insurance/Search.vue"),
	},
	{
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import("@/views/404.vue"),
	},
	{
		path: "/error",
		name: "error",
		component: () => import("@/views/error.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});
router.beforeEach((to, from, next) => {
	const titleText = to.name;
	const words = titleText.split(" ");
	const wordslength = words.length;
	for (let i = 0; i < wordslength; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1);
	}

	document.title = "Dashcode  - " + words;
	next();
});

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;

import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
	state: () => ({
		token: null,
		first_name: null,
		last_name: null,
		email: null,
		currency_code: null,
		base_currency: null,
	}),
	actions: {
		setUserDetails(res) {
			this.$state.token = res.data.token;
			this.$state.first_name = res.data.first_name;
			this.$state.last_name = res.data.last_name;
			this.$state.email = res.data.email;
			this.$state.currency_code = res.data.currency_code;
			this.$state.base_currency = res.data.base_currency;
		},

		setCurrency(res) {
			this.$state.currency_code = res;
		},

		async fetchUser() {
			let res = await axios.get("api/users/" + this.$state.token);

			console.log(res);
			this.$state.token = res.data.user.token;
			this.$state.first_name = res.data.user.first_name;
			this.$state.last_name = res.data.user.last_name;
			this.$state.email = res.data.user.email;
			this.$state.currency_code = res.data.currency_code;
			this.$state.base_currency = res.data.base_currency;

			// if (res.data.user.image) {
			//   this.$state.image =
			//     import.meta.env.VITE_APP_API_URL +
			//     "/images/users/" +
			//     res.data.user.image;
			// } else {
			//   this.$state.image =
			//     import.meta.env.VITE_APP_URL + "DefaultUserAvatar.png";
			// }
		},

		// userImage(image) {
		//   return import.meta.env.VITE_APP_API_URL + "/images/users/" + image;
		// },

		clearUser() {
			this.$state.token = null;
			this.$state.first_name = null;
			this.$state.last_name = null;
			this.$state.email = null;
			this.$state.currency_code = null;
			this.$state.base_currency = null;
		},
	},
	persist: true,
});
